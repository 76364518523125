<template>
  <b-modal
        ref="modal-findings"
        :id="`modal-${data.form}`"
        centered
        size="lg"
        :title="titleModal"
        modal-class="modal-primary"
        no-close-on-backdrop
        no-close-on-esc
    >

        <div class="contentModal mt-1">{{ descriptionModal }}</div>            
        
        <b-form-textarea
            id="textarea"            
            placeholder="Ingrese texto..."
            v-model="findingsModal"
            class="my-2"
            rows="5"
        >                
        </b-form-textarea>

        <template #modal-footer>

            <b-button
                variant="primary"
                class="float-right"
                @click="updateFindings()"
            >
                Guardar
            </b-button>

            <b-button variant="outline-danger" @click="cancelar()">
                Cancelar
            </b-button>
        </template>
    </b-modal>
</template>

<script>

    export default {
        data() {
            return {
                data: {},
                titleModal: null,
                descriptionModal: "Ingrese sus hallazgos:",	
                findingsModal: null
            }
        },
        methods: {
            openModal (data) {
                this.data = data
                this.titleModal = `HALLAZGOS ${data.month} ${data.year}`;      
                this.findingsModal = data.findings; 
                this.$refs['modal-findings'].show();
            },
            updateFindings(){
                let data = {
                    findings: this.findingsModal,
                    form: this.data.form
                }

                this.$emit('update-findings', data); 
                this.$refs['modal-findings'].hide();
            },
            cancelar(){
                this.$refs['modal-findings'].hide();
            }
        }
    }

</script>