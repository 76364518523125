<template>
  <div>
    <b-button
        id="buttonFindings"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="variant"
        class="btn-icon rounded-circle"
        v-b-tooltip.hover.on.v-dark
        title="HALLAZGOS"
        @click="openModalFindings()"
    >
        <feather-icon icon="MessageSquareIcon" />
    </b-button>
  </div>
</template>

<script>

    import Ripple from 'vue-ripple-directive'

    export default {
        props: {
            findings: String,
            month: String,
            year: Number,
            form: Number
        },
        directives: {
            Ripple
        },
        computed: {
            variant () {
                return this.findings ? 'outline-info' : 'outline-dark'
            }
        },
        methods: {
            openModalFindings () {
                    const data = {
                        form: this.form,
                        month: this.month,
                        year: this.year,
                        findings: this.findings
                    }

                    this.$emit('open-modal-findings', data); 
                }
        }
    }

</script>