<template>
    <div>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row>
                        <b-col sm="4" md="6">
                            <b-form-group
                                label="Seleccionar Año(s)"
                                label-for="year"
                            >
                                <v-select
                                    v-model="filtersForm.years"
                                    label="name"
                                    :options="years"
                                    multiple
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="6">
                            <b-form-group
                                label="Seleccionar Rubro(s)"
                                label-for="business_area"
                            >
                                <v-select
                                    v-model="filtersForm.business_areas"
                                    label="name"
                                    multiple
                                    :options="business_areas"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4" md="12">
                            <b-form-group
                                label="Seleccionar Proveedor(s)"
                                label-for="provider"
                            >
                                <v-select
                                    v-model="filtersForm.providers"
                                    label="name"
                                    multiple
                                    :options="providers"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="12" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                :block="true"
                                :disabled="btnFilterDisabled"
                                @click="filterData"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                FILTRAR
                            </b-button>
                            <hr>
                        </b-col>
                    </b-row>

                    <json-excel :data="tableExport()" name="resultados_rev_lab.xls"></json-excel>
                    
                    <b-table
                        ref="table"
                        striped
                        hover
                        responsive
                        class="position-relative text-center table-resultados-rev-lab mb-0"
                        :class="tableClass"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >

                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>

                        <template #cell(score_ene_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ene_2023)">{{ data.item.score_ene_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ene_2023 !== '-'" :year="2023" :findings="data.item.findings_ene_2023" month="ENERO" :form="data.item.form_ene_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ene_2023 !== '-'" :year="2023" :comments="data.item.comments_ene_2023" month="ENERO" :form="data.item.form_ene_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_feb_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_feb_2023)">{{ data.item.score_feb_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_feb_2023 !== '-'" :year="2023" :findings="data.item.findings_feb_2023" month="FEBRERO" :form="data.item.form_feb_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_feb_2023 !== '-'" :year="2023" :comments="data.item.comments_feb_2023" month="FEBRERO" :form="data.item.form_feb_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_mar_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_mar_2023)">{{ data.item.score_mar_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_mar_2023 !== '-'" :year="2023" :findings="data.item.findings_mar_2023" month="MARZO" :form="data.item.form_mar_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_mar_2023 !== '-'" :year="2023" :comments="data.item.comments_mar_2023" month="MARZO" :form="data.item.form_mar_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_abr_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_abr_2023)">{{ data.item.score_abr_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_abr_2023 !== '-'" :year="2023" :findings="data.item.findings_abr_2023" month="ABRIL" :form="data.item.form_abr_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_abr_2023 !== '-'" :year="2023" :comments="data.item.comments_abr_2023" month="ABRIL" :form="data.item.form_abr_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_may_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_may_2023)">{{ data.item.score_may_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_may_2023 !== '-'" :year="2023" :findings="data.item.findings_may_2023" month="MAYO" :form="data.item.form_may_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_may_2023 !== '-'" :year="2023" :comments="data.item.comments_may_2023" month="MAYO" :form="data.item.form_may_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jun_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jun_2023)">{{ data.item.score_jun_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jun_2023 !== '-'" :year="2023" :findings="data.item.findings_jun_2023" month="JUNIO" :form="data.item.form_jun_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jun_2023 !== '-'" :year="2023" :comments="data.item.comments_jun_2023" month="JUNIO" :form="data.item.form_jun_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jul_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jul_2023)">{{ data.item.score_jul_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jul_2023 !== '-'" :year="2023" :findings="data.item.findings_jul_2023" month="JULIO" :form="data.item.form_jul_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jul_2023 !== '-'" :year="2023" :comments="data.item.comments_jul_2023" month="JULIO" :form="data.item.form_jul_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_ago_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ago_2023)">{{ data.item.score_ago_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ago_2023 !== '-'" :year="2023" :findings="data.item.findings_ago_2023" month="AGOSTO" :form="data.item.form_ago_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ago_2023 !== '-'" :year="2023" :comments="data.item.comments_ago_2023" month="AGOSTO" :form="data.item.form_ago_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_sep_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_sep_2023)">{{ data.item.score_sep_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_sep_2023 !== '-'" :year="2023" :findings="data.item.findings_sep_2023" month="SEPTIEMBRE" :form="data.item.form_sep_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_sep_2023 !== '-'" :year="2023" :comments="data.item.comments_sep_2023" month="SEPTIEMBRE" :form="data.item.form_sep_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_oct_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_oct_2023)">{{ data.item.score_oct_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_oct_2023 !== '-'" :year="2023" :findings="data.item.findings_oct_2023" month="OCTUBRE" :form="data.item.form_oct_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_oct_2023 !== '-'" :year="2023" :comments="data.item.comments_oct_2023" month="OCTUBRE" :form="data.item.form_oct_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_nov_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_nov_2023)">{{ data.item.score_nov_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_nov_2023 !== '-'" :year="2023" :findings="data.item.findings_nov_2023" month="NOVIEMBRE" :form="data.item.form_nov_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_nov_2023 !== '-'" :year="2023" :comments="data.item.comments_nov_2023" month="NOVIEMBRE" :form="data.item.form_nov_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_dic_2023)="data">
                            <b :class="getDataScoreToTabe(data.item.score_dic_2023)">{{ data.item.score_dic_2023 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_dic_2023 !== '-'" :year="2023" :findings="data.item.findings_dic_2023" month="DICIEMBRE" :form="data.item.form_dic_2023" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_dic_2023 !== '-'" :year="2023" :comments="data.item.comments_dic_2023" month="DICIEMBRE" :form="data.item.form_dic_2023" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <!-- ===================================================================================================================================== -->

                        <template #cell(score_ene_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ene_2024)">{{ data.item.score_ene_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ene_2024 !== '-'" :year="2024" :findings="data.item.findings_ene_2024" month="ENERO" :form="data.item.form_ene_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ene_2024 !== '-'" :year="2024" :comments="data.item.comments_ene_2024" month="ENERO" :form="data.item.form_ene_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_feb_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_feb_2024)">{{ data.item.score_feb_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_feb_2024 !== '-'" :year="2024" :findings="data.item.findings_feb_2024" month="FEBRERO" :form="data.item.form_feb_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_feb_2024 !== '-'" :year="2024" :comments="data.item.comments_feb_2024" month="FEBRERO" :form="data.item.form_feb_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_mar_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_mar_2024)">{{ data.item.score_mar_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_mar_2024 !== '-'" :year="2024" :findings="data.item.findings_mar_2024" month="MARZO" :form="data.item.form_mar_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_mar_2024 !== '-'" :year="2024" :comments="data.item.comments_mar_2024" month="MARZO" :form="data.item.form_mar_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_abr_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_abr_2024)">{{ data.item.score_abr_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_abr_2024 !== '-'" :year="2024" :findings="data.item.findings_abr_2024" month="ABRIL" :form="data.item.form_abr_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_abr_2024 !== '-'" :year="2024" :comments="data.item.comments_abr_2024" month="ABRIL" :form="data.item.form_abr_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_may_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_may_2024)">{{ data.item.score_may_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_may_2024 !== '-'" :year="2024" :findings="data.item.findings_may_2024" month="MAYO" :form="data.item.form_may_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_may_2024 !== '-'" :year="2024" :comments="data.item.comments_may_2024" month="MAYO" :form="data.item.form_may_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jun_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jun_2024)">{{ data.item.score_jun_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jun_2024 !== '-'" :year="2024" :findings="data.item.findings_jun_2024" month="JUNIO" :form="data.item.form_jun_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jun_2024 !== '-'" :year="2024" :comments="data.item.comments_jun_2024" month="JUNIO" :form="data.item.form_jun_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jul_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jul_2024)">{{ data.item.score_jul_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jul_2024 !== '-'" :year="2024" :findings="data.item.findings_jul_2024" month="JULIO" :form="data.item.form_jul_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jul_2024 !== '-'" :year="2024" :comments="data.item.comments_jul_2024" month="JULIO" :form="data.item.form_jul_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_ago_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ago_2024)">{{ data.item.score_ago_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ago_2024 !== '-'" :year="2024" :findings="data.item.findings_ago_2024" month="AGOSTO" :form="data.item.form_ago_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ago_2024 !== '-'" :year="2024" :comments="data.item.comments_ago_2024" month="AGOSTO" :form="data.item.form_ago_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_sep_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_sep_2024)">{{ data.item.score_sep_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_sep_2024 !== '-'" :year="2024" :findings="data.item.findings_sep_2024" month="SEPTIEMBRE" :form="data.item.form_sep_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_sep_2024 !== '-'" :year="2024" :comments="data.item.comments_sep_2024" month="SEPTIEMBRE" :form="data.item.form_sep_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_oct_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_oct_2024)">{{ data.item.score_oct_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_oct_2024 !== '-'" :year="2024" :findings="data.item.findings_oct_2024" month="OCTUBRE" :form="data.item.form_oct_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_oct_2024 !== '-'" :year="2024" :comments="data.item.comments_oct_2024" month="OCTUBRE" :form="data.item.form_oct_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_nov_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_nov_2024)">{{ data.item.score_nov_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_nov_2024 !== '-'" :year="2024" :findings="data.item.findings_nov_2024" month="NOVIEMBRE" :form="data.item.form_nov_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_nov_2024 !== '-'" :year="2024" :comments="data.item.comments_nov_2024" month="NOVIEMBRE" :form="data.item.form_nov_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_dic_2024)="data">
                            <b :class="getDataScoreToTabe(data.item.score_dic_2024)">{{ data.item.score_dic_2024 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_dic_2024 !== '-'" :year="2024" :findings="data.item.findings_dic_2024" month="DICIEMBRE" :form="data.item.form_dic_2024" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_dic_2024 !== '-'" :year="2024" :comments="data.item.comments_dic_2024" month="DICIEMBRE" :form="data.item.form_dic_2024" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <!-- ===================================================================================================================================== -->

                        <template #cell(score_ene_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ene_2025)">{{ data.item.score_ene_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ene_2025 !== '-'" :year="2025" :findings="data.item.findings_ene_2025" month="ENERO" :form="data.item.form_ene_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ene_2025 !== '-'" :year="2025" :comments="data.item.comments_ene_2025" month="ENERO" :form="data.item.form_ene_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_feb_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_feb_2025)">{{ data.item.score_feb_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_feb_2025 !== '-'" :year="2025" :findings="data.item.findings_feb_2025" month="FEBRERO" :form="data.item.form_feb_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_feb_2025 !== '-'" :year="2025" :comments="data.item.comments_feb_2025" month="FEBRERO" :form="data.item.form_feb_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_mar_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_mar_2025)">{{ data.item.score_mar_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_mar_2025 !== '-'" :year="2025" :findings="data.item.findings_mar_2025" month="MARZO" :form="data.item.form_mar_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_mar_2025 !== '-'" :year="2025" :comments="data.item.comments_mar_2025" month="MARZO" :form="data.item.form_mar_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_abr_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_abr_2025)">{{ data.item.score_abr_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_abr_2025 !== '-'" :year="2025" :findings="data.item.findings_abr_2025" month="ABRIL" :form="data.item.form_abr_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_abr_2025 !== '-'" :year="2025" :comments="data.item.comments_abr_2025" month="ABRIL" :form="data.item.form_abr_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_may_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_may_2025)">{{ data.item.score_may_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_may_2025 !== '-'" :year="2025" :findings="data.item.findings_may_2025" month="MAYO" :form="data.item.form_may_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_may_2025 !== '-'" :year="2025" :comments="data.item.comments_may_2025" month="MAYO" :form="data.item.form_may_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jun_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jun_2025)">{{ data.item.score_jun_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jun_2025 !== '-'" :year="2025" :findings="data.item.findings_jun_2025" month="JUNIO" :form="data.item.form_jun_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jun_2025 !== '-'" :year="2025" :comments="data.item.comments_jun_2025" month="JUNIO" :form="data.item.form_jun_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_jul_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_jul_2025)">{{ data.item.score_jul_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_jul_2025 !== '-'" :year="2025" :findings="data.item.findings_jul_2025" month="JULIO" :form="data.item.form_jul_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_jul_2025 !== '-'" :year="2025" :comments="data.item.comments_jul_2025" month="JULIO" :form="data.item.form_jul_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_ago_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_ago_2025)">{{ data.item.score_ago_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_ago_2025 !== '-'" :year="2025" :findings="data.item.findings_ago_2025" month="AGOSTO" :form="data.item.form_ago_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_ago_2025 !== '-'" :year="2025" :comments="data.item.comments_ago_2025" month="AGOSTO" :form="data.item.form_ago_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_sep_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_sep_2025)">{{ data.item.score_sep_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_sep_2025 !== '-'" :year="2025" :findings="data.item.findings_sep_2025" month="SEPTIEMBRE" :form="data.item.form_sep_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_sep_2025 !== '-'" :year="2025" :comments="data.item.comments_sep_2025" month="SEPTIEMBRE" :form="data.item.form_sep_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_oct_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_oct_2025)">{{ data.item.score_oct_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_oct_2025 !== '-'" :year="2025" :findings="data.item.findings_oct_2025" month="OCTUBRE" :form="data.item.form_oct_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_oct_2025 !== '-'" :year="2025" :comments="data.item.comments_oct_2025" month="OCTUBRE" :form="data.item.form_oct_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_nov_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_nov_2025)">{{ data.item.score_nov_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_nov_2025 !== '-'" :year="2025" :findings="data.item.findings_nov_2025" month="NOVIEMBRE" :form="data.item.form_nov_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_nov_2025 !== '-'" :year="2025" :comments="data.item.comments_nov_2025" month="NOVIEMBRE" :form="data.item.form_nov_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>

                        <template #cell(score_dic_2025)="data">
                            <b :class="getDataScoreToTabe(data.item.score_dic_2025)">{{ data.item.score_dic_2025 }}</b>
                            <div class="d-flex justify-content-center align-items-center">
                                <ButtonFindings v-if="data.item.score_dic_2025 !== '-'" :year="2025" :findings="data.item.findings_dic_2025" month="DICIEMBRE" :form="data.item.form_dic_2025" @open-modal-findings="openModalFindings($event)" />
                                <ButtonComments v-if="data.item.score_dic_2025 !== '-'" :year="2025" :comments="data.item.comments_dic_2025" month="DICIEMBRE" :form="data.item.form_dic_2025" @open-modal-comments="openModalComments($event)" />
                            </div>
                        </template>
                        
                    </b-table>

                    <ModalFindings
                        ref="modalFindings"
                        @update-findings="updateFindings($event)"
                    />

                    <ModalComments
                        ref="modalComments"
                        @update-comments="updateComments($event)"
                    />

                    <b-row v-if="revlabGraphicResults.series[0].data.length">
                        <b-col cols="12">
                            <hr>
                            <hr>
                        </b-col>
                        <b-col cols="3">
                            <json-excel :data="graphicTableExport()" name="resultados_rev_lab_porcentaje_mensual.xls"></json-excel>

                            <b-table
                                ref="table2"
                                striped
                                hover
                                responsive
                                class="position-relative text-center"
                                :per-page="perPage2"
                                :current-page="currentPage2"
                                :items="items2"
                                :fields="fields2"
                                :sort-by.sync="sortBy2"
                                :sort-desc.sync="sortDesc2"
                                :sort-direction="sortDirection2"
                                :filter="filter2"
                                :filter-included-fields="filterOn2"
                                show-empty
                                empty-text="No se encontraron registros coincidentes"
                            >
            
                            </b-table>
                        </b-col>
                        <b-col cols="9">
                            <vue-apex-charts
                                ref="revlabGraphicResultsRef"
                                type="line"
                                height="400"
                                class="mt-2 mb-1"
                                :options="revlabGraphicResults.chartOptions"
                                :series="revlabGraphicResults.series"
                            />                            
                        </b-col> 
                    </b-row>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import ButtonFindings from '@core/components/button-findings/ButtonFindings.vue';
    import ButtonComments from '@core/components/button-comments/ButtonComments.vue';
    import ModalFindings from '@core/components/button-findings/ModalFindings.vue';
    import ModalComments from '@core/components/button-comments/ModalComments.vue';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import moment from 'moment';
    import vSelect from 'vue-select';
    import axios from '@axios';

    export default {
        components: {
            ToastificationContent,
            ButtonFindings,
            ButtonComments,
            ModalFindings,
            ModalComments,
            VueApexCharts,
            JsonExcel,
            vSelect
        },
        data() {
            return {
                btnFilterDisabled: true,

                perPage: 50000,
                currentPage: 1,

                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,   
                filterOn: [],
                fields: [],
                items: [],

                // ====================== 

                perPage2: 50000,
                currentPage2: 1,

                sortBy2: '',
                sortDesc2: false,
                sortDirection2: 'asc',
                filter2: null,   
                filterOn2: [],
                fields2: [
                    {
                        key: 'label',
                        label: 'MES',
                        sortable: true
                    },
                    {
                        key: 'percent',
                        label: '%',
                        sortable: true
                    }
                ],
                items2: [],

                // ====================== 
                
                filtersForm: {
                    years: [],
                    business_areas: [],
                    providers: []
                },

                years2: [],
                years: [],
                business_areas: [],
                providers: [],

                revlabGraphicResults: {
                    chartOptions: {
                        chart: {
                            height: 400,
                            type: 'line',
                            zoom: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        title: {
                            text: '% CUMPLIMIENTO DE REV. LABORALES',
                            align: 'center'
                        },
                        xaxis: {
                            categories: [],
                        }
                    },
                    series: [{
                        name: "% CUMPLIMIENTO",
                        data: []
                    }],
                }
            }
        },
        computed: {
            tableClass () {
                return `table-resultados-rev-lab--items-${this.fields.length}`
            }
        },
        async beforeMount() {
            await this.getBusinessArea()
            await this.getProviders()

            const currentYear = parseInt(moment().format('YYYY'));

            this.years = [];
            
            for (var i = 2023; i <= currentYear; i++)
            {
                this.years.push({ id: i, name: i });
            }

            this.filtersForm.years = [this.years.find(year => year.id === currentYear)];

            this.revlabGraphicResults.chartOptions.title.text = `% CUMPLIMIENTO DE REV. LABORALES ${currentYear}`;

            await this.getDataTable();
        },
        methods: {
            async getBusinessArea(){
                const response = await axios.get('/business-area/select');
                this.business_areas = response.data;
            },
            async getProviders(){
                const response = await axios.get('/providers/select-2');
                this.providers = response.data;
            },
            async getDataTable (){
                this.btnFilterDisabled = true;

                const years = this.filtersForm.years ? this.filtersForm.years.map(item => item.id) : [];

                const businessAreas = this.filtersForm.business_areas ? this.filtersForm.business_areas.map(item => item.id) : [];
                const providers = this.filtersForm.providers ? this.filtersForm.providers.map(item => item.id) : [];
                
                let response = await axios.get(`/reporteria/table-resultados-rev-lab?years=${years.join(',')}&businessAreas=${businessAreas.join(',')}&providers=${providers.join(',')}`);

                this.fields = response.data.fields;
                this.items = response.data.items;
                this.perPage = this.items.length;
                this.years2 = response.data.years;

                this.items2 = response.data.graphic.series;
                this.perPage2 = this.items2.length;

                this.revlabGraphicResults.chartOptions.xaxis.categories = response.data.graphic.categories;
                this.revlabGraphicResults.series[0].data = response.data.graphic.series.map(item => item.avg);

                if (this.$refs["revlabGraphicResultsRef"])
                {
                    await this.$refs["revlabGraphicResultsRef"].refresh();
                }

                this.currentPage = 1;
                this.sortBy = '';
                this.sortDesc = false;
                this.sortDirection = 'asc';
                this.filter = null;

                this.btnFilterDisabled = false;
            },
            getDataScoreToTabe (score){
                let color;
                
                if (score >= 95){
                    color = 'text-success';
                } else if (score >= 90 && score <= 94.99){
                    color = 'text-warning';
                } else if (score < 90) {
                    color = 'text-danger';
                } else {
                    color = 'text-dark';
                }

                return color;
            },
            async filterData (){
                await this.getDataTable();
                this.$refs['table'].refresh();
            },
            openModalFindings (data) {
                this.$refs["modalFindings"].openModal(data);
            },
            openModalComments (data) {
                this.$refs["modalComments"].openModal(data);
            },
            async updateFindings (data){
                const response = await axios.put('/reporteria/update-findings', data);

                await this.getDataTable();

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });
            },
            async updateComments (data){
                const response = await axios.put('/reporteria/update-comments', data);

                await this.getDataTable();

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });
            },
            tableExport (){
                let array = [];

                for (let a = 0; a < this.items.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1),
                        'PROVEEDOR': this.items[a]['proveedor'],
                        'RUBRO': this.items[a]['rubro']
                    };

                    for (let b = 0; b < this.years2.length; b++)
                    {
                        const year = this.years2[b];

                        if (this.items[a][`score_ene_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (ENE-${year})`] = this.items[a][`score_ene_${year}`];
                            objBody[`HALLAZGOS (ENE-${year})`] = this.items[a][`findings_ene_${year}`];
                            objBody[`COMENTARIOS (ENE-${year})`] = this.items[a][`comments_ene_${year}`];
                        }

                        if (this.items[a][`score_feb_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (FEB-${year})`] = this.items[a][`score_feb_${year}`];
                            objBody[`HALLAZGOS (FEB-${year})`] = this.items[a][`findings_feb_${year}`];
                            objBody[`COMENTARIOS (FEB-${year})`] = this.items[a][`comments_feb_${year}`];
                        }

                        if (this.items[a][`score_mar_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (MAR-${year})`] = this.items[a][`score_mar_${year}`];
                            objBody[`HALLAZGOS (MAR-${year})`] = this.items[a][`findings_mar_${year}`];
                            objBody[`COMENTARIOS (MAR-${year})`] = this.items[a][`comments_mar_${year}`];
                        }

                        if (this.items[a][`score_abr_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (ABR-${year})`] = this.items[a][`score_abr_${year}`];
                            objBody[`HALLAZGOS (ABR-${year})`] = this.items[a][`findings_abr_${year}`];
                            objBody[`COMENTARIOS (ABR-${year})`] = this.items[a][`comments_abr_${year}`];
                        }

                        if (this.items[a][`score_may_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (MAY-${year})`] = this.items[a][`score_may_${year}`];
                            objBody[`HALLAZGOS (MAY-${year})`] = this.items[a][`findings_may_${year}`];
                            objBody[`COMENTARIOS (MAY-${year})`] = this.items[a][`comments_may_${year}`];
                        }

                        if (this.items[a][`score_jun_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (JUN-${year})`] = this.items[a][`score_jun_${year}`];
                            objBody[`HALLAZGOS (JUN-${year})`] = this.items[a][`findings_jun_${year}`];
                            objBody[`COMENTARIOS (JUN-${year})`] = this.items[a][`comments_jun_${year}`];
                        }

                        if (this.items[a][`score_jul_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (JUL-${year})`] = this.items[a][`score_jul_${year}`];
                            objBody[`HALLAZGOS (JUL-${year})`] = this.items[a][`findings_jul_${year}`];
                            objBody[`COMENTARIOS (JUL-${year})`] = this.items[a][`comments_jul_${year}`];
                        }

                        if (this.items[a][`score_ago_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (AGO-${year})`] = this.items[a][`score_ago_${year}`];
                            objBody[`HALLAZGOS (AGO-${year})`] = this.items[a][`findings_ago_${year}`];
                            objBody[`COMENTARIOS (AGO-${year})`] = this.items[a][`comments_ago_${year}`];
                        }

                        if (this.items[a][`score_sep_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (SEP-${year})`] = this.items[a][`score_sep_${year}`];
                            objBody[`HALLAZGOS (SEP-${year})`] = this.items[a][`findings_sep_${year}`];
                            objBody[`COMENTARIOS (SEP-${year})`] = this.items[a][`comments_sep_${year}`];
                        }

                        if (this.items[a][`score_oct_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (OCT-${year})`] = this.items[a][`score_oct_${year}`];
                            objBody[`HALLAZGOS (OCT-${year})`] = this.items[a][`findings_oct_${year}`];
                            objBody[`COMENTARIOS (OCT-${year})`] = this.items[a][`comments_oct_${year}`];
                        }

                        if (this.items[a][`score_nov_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (NOV-${year})`] = this.items[a][`score_nov_${year}`];
                            objBody[`HALLAZGOS (NOV-${year})`] = this.items[a][`findings_nov_${year}`];
                            objBody[`COMENTARIOS (NOV-${year})`] = this.items[a][`comments_nov_${year}`];
                        }

                        if (this.items[a][`score_dic_${year}`])
                        {
                            objBody[`PUNTAJE OBTENIDO (DIC-${year})`] = this.items[a][`score_dic_${year}`];
                            objBody[`HALLAZGOS (DIC-${year})`] = this.items[a][`findings_dic_${year}`];
                            objBody[`COMENTARIOS (DIC-${year})`] = this.items[a][`comments_dic_${year}`];
                        }
                    }

                    array.push(objBody);
                }

                return array;
            },
            graphicTableExport (){
                let array = [];

                for (let a = 0; a < this.items2.length; a++)
                {
                    let objBody = {
                        'MES': this.items2[a]['label'],
                        '%': this.items2[a]['percent']
                    };

                    array.push(objBody);
                }

                return array;
            }
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style>

    .table-resultados-rev-lab--items-1 .table.b-table,
    .table-resultados-rev-lab--items-2 .table.b-table,
    .table-resultados-rev-lab--items-3 .table.b-table,
    .table-resultados-rev-lab--items-4 .table.b-table,
    .table-resultados-rev-lab--items-5 .table.b-table,
    .table-resultados-rev-lab--items-6 .table.b-table,
    .table-resultados-rev-lab--items-7 .table.b-table,
    .table-resultados-rev-lab--items-8 .table.b-table {
        width: 100%;
    }

    .table-resultados-rev-lab--items-9 .table.b-table {
        width: 150%;
    }

    .table-resultados-rev-lab--items-10 .table.b-table {
        width: 155%;
    }

    .table-resultados-rev-lab--items-11 .table.b-table {
        width: 160%;
    }

    .table-resultados-rev-lab--items-12 .table.b-table {
        width: 165%;
    }

    .table-resultados-rev-lab--items-13 .table.b-table {
        width: 170%;
    }

    .table-resultados-rev-lab--items-14 .table.b-table {
        width: 175%;
    }

    .table-resultados-rev-lab--items-15 .table.b-table {
        width: 180%;
    }

    .table-resultados-rev-lab--items-16 .table.b-table {
        width: 185%;
    }

    .table-resultados-rev-lab--items-17 .table.b-table {
        width: 190%;
    }

    .table-resultados-rev-lab--items-18 .table.b-table {
        width: 195%;
    }

    .table-resultados-rev-lab--items-19 .table.b-table {
        width: 200%;
    }

    .table-resultados-rev-lab--items-20 .table.b-table {
        width: 205%;
    }

    .table-resultados-rev-lab--items-21 .table.b-table {
        width: 210%;
    }

    .table-resultados-rev-lab--items-22 .table.b-table {
        width: 215%;
    }

    .table-resultados-rev-lab--items-23 .table.b-table {
        width: 220%;
    }

    .table-resultados-rev-lab--items-24 .table.b-table {
        width: 225%;
    }

    .table-resultados-rev-lab--items-25 .table.b-table {
        width: 230%;
    }

    .table-resultados-rev-lab--items-26 .table.b-table {
        width: 235%;
    }

    .table-resultados-rev-lab--items-27 .table.b-table {
        width: 240%;
    }

    .table-resultados-rev-lab--items-28 .table.b-table {
        width: 245%;
    }

    .table-resultados-rev-lab--items-29 .table.b-table {
        width: 250%;
    }

    .table-resultados-rev-lab .b-table tbody tr td:nth-child(2) {
        text-align: left !important;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>