<template>
    <div>
        <b-button
            id="buttonComments"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="variant"
            class="btn-icon rounded-circle"
            v-b-tooltip.hover.on.v-dark
            title="COMENTARIOS"
            @click="openModalComments()"
        >
            <feather-icon icon="MessageCircleIcon" />
        </b-button>
    </div>
</template>

<script>

    import Ripple from 'vue-ripple-directive'

    export default {
        props: {
            comments: String,
            month: String,
            year: Number,
            form: Number    
        },
        directives: {
            Ripple
        },
        computed: {
            variant () {
                return this.comments ? 'outline-info' : 'outline-dark'
            }
        },
        methods: {
            openModalComments () {
                const data = {
                    form: this.form,
                    month: this.month,
                    year: this.year,
                    comments: this.comments
                }

                this.$emit('open-modal-comments', data); 
            }
        }
    }
    
</script>